<template>
  <div>
    <v-row>
      <v-col class="pt-0 my-2">
        <div v-if="examStatus == 'exhausted'" class="font-weight-bold">
          {{ $t("c_trainingexamsummary.t_no_more_exam_attempts_available") }}
        </div>
        <div v-if="examStatus == 'expired'" class="font-weight-bold">
          {{ $t("c_trainingexamsummary.t_enrollment_expired") }}
        </div>
        <v-btn v-else-if="coursePassed" small :to="{ name: 'trainingexam', params: { id: enrollment.id } }">
          {{ $t("t_retake") }}
        </v-btn>
        <v-btn
          v-else-if="enrollment && examStatus != 'exhausted' && examStatus != 'expired'"
          color="primary"
          :to="{ name: 'trainingexam', params: { id: enrollment.id } }"
        >
          {{ examStatus == "retake" ? $t("t_retake") : examStatus == "inprogress" ? $t("t_resume") : $t("t_start") }}
          {{ $t("t_exam") }}</v-btn
        >
      </v-col>
    </v-row>
    <v-row
      class="mb-4"
      dense
      v-for="(item, i) in examAttempts"
      :key="i"
      :style="i != examAttempts.length - 1 ? 'border-bottom: 1px solid #555' : ''"
    >
      <v-col class="pt-0 py-1 mb-8">
        <v-row>
          <v-col>
            <v-list-item-subtitle class="subheadline-text text-uppercase">{{ $t("t_attempt") }}</v-list-item-subtitle>
            <v-list-item-title> #{{ i + 1 }}</v-list-item-title>
          </v-col>
          <v-col>
            <v-list-item-subtitle class="subheadline-text text-uppercase">{{ $t("t_date") }}</v-list-item-subtitle>
            <v-list-item-title>{{ formatDateAbbrMonthDayYear(item.startedOn) }}</v-list-item-title>
          </v-col>
          <v-col>
            <v-list-item-subtitle class="subheadline-text text-uppercase">{{ $t("t_score") }}</v-list-item-subtitle>
            <v-list-item-title v-if="item.score !== null">{{
              item.score + "/" + item.possibleScore
            }}</v-list-item-title>
            <v-list-item-title v-else> <v-icon color="grey lighten-1">mdi-cancel</v-icon></v-list-item-title>
          </v-col>
          <v-col>
            <v-list-item-subtitle class="subheadline-text text-uppercase">{{ $t("t_status") }}</v-list-item-subtitle>
            <v-list-item-title class="">
              <span v-if="item.passed !== null">
                <span v-if="item.passed == true" class="font-weight-bold success--text">
                  {{ $t("t_passed") }}
                </span>
                <span v-else class="red--text"> {{ $t("t_failed") }} </span>
              </span>
              <span v-else><v-icon color="grey lighten-1">mdi-cancel</v-icon></span>
            </v-list-item-title>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="py-0">
            <span v-if="!item.submittedOn">{{ $t("c_trainingexamsummary.t_exam_never_submitted") }}</span>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { TrainingService } from "@/services";
import Helpers from "@/mixins/helpers";
import { DateTime } from "luxon";

export default {
  name: "TrainingExamSummary",
  mixins: [Helpers],
  props: {
    course: {
      type: Object,
    },
  },
  data: () => ({
    enrollment: null,
    examAttempts: null,
    examStatus: null, //new, inprogress, retake, exhausted
    coursePassed: null,
  }),
  methods: {
    async checkEnrollment() {
      //No courseEnrollmentId on course object... so grab all and find enrollment for this course
      let r = await TrainingService.getEnrollments();
      const enrollments = r.data;
      if (enrollments) {
        const courseId = this.$route.params.id ? this.$route.params.id : this.course.id;
        this.enrollment = enrollments.findLast((e) => e.courseId == courseId && !e.endedOn);
        if (!this.enrollment) return;
        r = await TrainingService.getExamAttempts({ courseEnrollmentId: this.enrollment.id });
        this.examAttempts = r.data;
        this.getExamStatus();
        this.coursePassed = this.examAttempts.some((a) => a.passed);
      }
    },

    getExamStatus() {
      if (!this.examAttempts.length) {
        this.examStatus = "new";
      } else if (
        this.examAttempts.find((a) => a.submittedOn === null && a.expiresOn >= DateTime.now().toUTC().toISO())
      ) {
        this.examStatus = "inprogress";
      } else if (
        this.enrollment.expiresOn < DateTime.now().toUTC().toISO() &&
        !this.examAttempts.some((a) => a.passed)
      ) {
        this.examStatus = "expired";
      } else if (this.examAttempts.length >= this.course.reattemptsAllowed + 1) {
        this.examStatus = "exhausted";
      } else {
        this.examStatus = "retake";
      }
    },
  },
  mounted() {
    this.checkEnrollment();
  },
};
</script>
