<template>
  <div class="webinar-sessions">
    <v-container class="pa-0" fluid>
      <v-card flat>
        <!-- <v-toolbar dark>
          <v-toolbar-title>{{ $t("c_webinar.t_webinar_sessions") }} </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar> -->
        <div class="mx-4 pa-4 mt-2 featured" :class="{ isdark: $vuetify.theme.dark }">
          <v-card-title v-if="registeredSession?.registeredOn && !registeredSession.passedOn" class="py-0 px-0">{{
            $t("c_webinar.t_registered_session")
          }}</v-card-title>
          <v-card-title v-else-if="registeredSession?.registeredOn && registeredSession.passedOn" class="py-0 px-0">{{
            $t("c_webinar.t_completed_webinar_session")
          }}</v-card-title>
          <v-card-title v-else class="py-0 px-0">{{ $t("c_webinar.t_webinar_sessions") }}</v-card-title>

          <div v-if="registeredSession?.registeredOn">
            <v-row>
              <!-- style="align-items: center" justify="center" -->
              <v-col cols="12" sm="4" md="3" v-if="registeredSession.passedOn">
                <div class="overline">{{ $t("c_webinar.t_passed_on") }}</div>
                {{ formatDate(registeredSession.passedOn) }}
                <br />
                <v-btn
                  :outlined="$vuetify.theme.dark"
                  color="success"
                  class="my-1 mt-3 rounded"
                  @click.stop="getCertificate(registeredSession)"
                >
                  {{ $t("c_webinar.t_certificate") }}
                </v-btn>
              </v-col>

              <v-col cols="12" sm="7" md="5" class="font-weight-bold" v-else>
                <div class="overline">{{ $t("t_start") }} {{ $t("t_time") }}</div>
                {{
                  formatDate(webinarSessions.find((s) => s.id == registeredSession.courseWebinarId)?.scheduledStartOn)
                }}
                {{
                  formatTime(webinarSessions.find((s) => s.id == registeredSession.courseWebinarId)?.scheduledStartOn)
                }}
                <br />

                <v-btn
                  v-if="registeredSession.registeredOn && !registeredSession.passedOn"
                  color="primary"
                  class="my-1 mt-3 rounded"
                  :href="sessionDetails.url"
                  target="_blank"
                >
                  {{ $t("c_webinar.t_attend") }}
                </v-btn>
              </v-col>

              <v-col cols="12" sm="5" md="7" :style="$vuetify.breakpoint.smAndUp ? 'text-align: right' : ''">
                <div class="overline">{{ $t("c_webinar.t_registered_on") }}</div>
                {{ formatDate(registeredSession.registeredOn) }}
                <br />

                <v-btn @click="unregister(registeredSession.id)" class="mt-2" small v-if="!registeredSession.passedOn"
                  >unregister</v-btn
                >
              </v-col>
            </v-row>
          </div>
          <div v-else>
            <v-list class="transparent">
              <template v-for="(session, index) in sorted_sessions">
                <v-list-item :key="index">
                  <!-- :class="{ 'grey darken-4': $vuetify.theme.dark }" -->
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ formatDate(session.scheduledStartOn) }} &nbsp;
                      {{ formatTime(session.scheduledStartOn) }}</v-list-item-title
                    >
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-btn
                      v-if="enrollmentDetails"
                      color="primary"
                      class="my-1 rounded"
                      @click.stop="register(session)"
                    >
                      {{ $t("c_webinar.t_register") }}
                    </v-btn>
                    <v-btn v-else color="primary" class="my-1 rounded" @click.stop="enrollToRegister(session)">
                      {{ $t("c_webinar.t_register") }}
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
                <v-divider v-if="index + 1 < webinarSessions.length" :key="`divider-${index}`"> </v-divider>
              </template>
            </v-list>
          </div>
        </div>

        <div>
          <div class="text-center py-4" v-if="!loading || webinarSessions.length < 1">
            {{ $t("c_webinar.t_no_sessions_available") }}
          </div>
        </div>
      </v-card>
    </v-container>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import Helpers from "@/mixins/helpers";
import debounce from "lodash/debounce";

export default {
  name: "WebinarSessions",
  props: {
    parent: {
      type: String,
    },
  },

  mixins: [Helpers],

  data() {
    return {
      enrollmentDetails: {},
      loading: false,
      sessionDetails: {},
      webinarSessions: [],
      webinarSessionAscending: false,
      webinarSessionsSkipNum: 0,
      webinarSessionTakeNum: 20,
      courseId: null,
      registeredSession: {},
      course: {},
    };
  },

  computed: {
    sorted_sessions() {
      return this.webinarSessions.slice().sort((a, b) => new Date(a.scheduledStartOn) - new Date(b.scheduledStartOn));
    },
  },

  methods: {
    ...mapActions("training", [
      "searchWebinars",
      "getWebinar",
      "searchWebinarRegistrations",
      "getRegistration",
      "deleteRegistration",
      "createWebinarRegistration",
      "genCertificate",
      "getEnrollmentDetails",
      "startEnrollment",
    ]),
    async searchWebinarSessions(reload = false) {
      if (!this.$online) {
        return;
      }
      if (reload) {
        this.trainingCoursesSkipNum = 0;
      }
      this.loading = true;
      let webinarParams = {
        "paging.ascending": this.webinarSessionAscending,
        "paging.skipnum": this.webinarSessionsSkipNum,
        "paging.takenum": this.webinarSessionTakeNum,
      };
      webinarParams.courseId = this.$route.params.id;
      webinarParams["paging.orderbyfield"] = "scheduledStartOn";
      const res = await this.searchWebinars(webinarParams);
      this.webinarSessions = await res.data;
      console.log(this.webinarSessions);
    },

    async searchRegisteredSessions() {
      this.loading = true;
      let regParams = {
        courseEnrollmentId: this.enrollmentDetails.id,
        courseId: this.$route.params.id,
        "paging.ascending": this.webinarSessionAscending,
        "paging.orderbyfield": "registeredOn",
        "paging.skipnum": this.webinarSessionsSkipNum,
        "paging.takenum": this.webinarSessionTakeNum,
      };

      const res = await this.searchWebinarRegistrations(regParams);
      this.registeredSession = await res.data[0];

      if (this.registeredSession) {
        const session = await this.getWebinar(this.registeredSession.courseWebinarId);
        this.sessionDetails = session.data;
      }
    },

    async getEnrollment() {
      let trainingParams = {
        "paging.ascending": this.webinarSessionAscending,
        "paging.skipnum": this.webinarSessionsSkipNum,
        "paging.takenum": this.webinarSessionTakeNum,
      };
      trainingParams["paging.orderbyfield"] = "enrolledOn";
      trainingParams.courseId = parseInt(this.$route.params.id);
      const r = await this.getEnrollmentDetails(trainingParams);
      this.enrollmentDetails = r;
    },

    async enrollToRegister(item) {
      const enrollment = await this.startEnrollment(this.courseId);
      const enrollmentId = await enrollment.data.courseEnrollmentId;
      const params = {
        courseWebinarId: item.id,
        courseEnrollmentId: enrollmentId,
      };

      await this.createWebinarRegistration(params);
      this.$snackbar.showMessage({
        content: this.$t("c_webinar.t_successful_enrollment_and_registration"),
        color: "success",
        timeout: "",
      });
      this.searchWebinarSessions(true);
      this.searchRegisteredSessions();
    },

    async register(item) {
      console.log(item);
      const params = {
        courseWebinarId: item.id,
        courseEnrollmentId: this.enrollmentDetails.id,
      };

      await this.createWebinarRegistration(params);
      this.$snackbar.showMessage({
        content: this.$t("c_webinar.t_successful_registration"),
        color: "success",
        timeout: "",
      });
      this.searchWebinarSessions(true);
      this.searchRegisteredSessions();
    },

    async unregister(id) {
      await this.deleteRegistration(id);
      this.$snackbar.showMessage({
        content: this.$t("c_webinar.t_successful_unregistration"),
        color: "success",
        timeout: "",
      });
      this.searchWebinarSessions(true);
      this.searchRegisteredSessions();
    },

    async getCertificate(registeredSession) {
      this.$emit("getCert", registeredSession);
    },

    searchTrainingCourses: debounce(function () {
      this.searchWebinarSessions(true);
      this.getEnrollment(true);
    }, 700),
  },
  created() {
    this.searchWebinarSessions(true);
    this.searchRegisteredSessions();
    this.courseId = this.$route.params.id;
    this.getEnrollment();
  },
};
</script>
<style lang="scss">
.webinar-sessions {
  .featured {
    border: 1px solid rgb(209, 224, 247) !important;
    border-radius: 5px;
    background-color: rgb(218, 229, 243) !important;
    &.isdark {
      border: 1px solid rgb(27 50 85) !important;
      border-radius: 5px;
      background-color: rgb(25 34 46) !important;
    }
  }
}
</style>
